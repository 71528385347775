/* requires:
polyfill.js
cart.js
*/

// this is the function that can or will render a product catalog if we need one, the html template of this file will need to change depending on how we want to display the product catalog
const renderProducts = (products, filterProducts = false) => {
  if (!products || products.length < 1) return;

  const localCart = cart.getLocalCart();
  const localCartId = cart.readCookie('cartId');
  const testPrice = cart.readCookie('_up4tp') ? `&tp=${cart.readCookie('_up4tp')}` : '';
  const couponCode = cart.readCookie('_up4cc') ? `&coupon=${cart.readCookie('_up4cc')}` : '';
  const groupId = cart.readCookie('_up4gid') ? `&groupId=${cart.readCookie('_up4gid')}` : '';

  if (filterProducts === true) {
    products = products.filter((product) => product.pricing.groupId !== null);
  }

  const displayTemplate = (product) => {
    const isProductInCart = localCart?.cartProducts.some((item) => item.productId === product.id) && localCartId;

    const priceObj = product?.pricing;
    const priceAfterDiscount =
      '$' +
      (priceObj?.discountAmount
        ? (priceObj.price - priceObj.discountAmount).toFixed(2).toString()
        : priceObj?.price?.toFixed(2).toString());
    const regularPrice = '$' + priceObj?.price?.toFixed(2).toString();
    const discountAmount = '$' + priceObj?.discountAmount?.toFixed(2).toString();
    const priceAfterDiscountRaw = priceObj?.discountAmount
      ? (priceObj.price - priceObj.discountAmount).toFixed(2).toString()
      : priceObj?.price?.toFixed(2).toString();
    const priceDollars = priceAfterDiscountRaw ? priceAfterDiscountRaw.split('.')[0] : null;
    const priceCents = priceAfterDiscountRaw
      ? priceAfterDiscountRaw.split('.')[1]
        ? priceAfterDiscountRaw.split('.')[1]
        : '00'
      : null;

    return `
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <h2 class="h3 mt-0"><a class="learnmore" href="#">${product.name}</a></h2>
              <p>${product.description}</p>
              <p class="fw-bolder">Pricing display options:</p>
              <div class="my-2">
                ${
                  priceObj.discountAmount
                    ? `<span class="regular-price-display m-2">Regular Price: <span class="regular-price" data-pid="${product.id}">${regularPrice}</span></span>`
                    : ''
                }
                <span class="m-2">Display Price: <span class="price-display" data-pid="${
                  product.id
                }">${priceAfterDiscount}</span></span>
                ${
                  priceObj.discountAmount
                    ? `<span class="savings-display m-2">Savings: <span class="savings-amount" data-pid="${product.id}">${discountAmount}</span></span></span>`
                    : ''
                }
              </div>
              <div class="price price-styled shown m-2" data-pid="${product.id}">
                <span class="dollar-sign">$</span>
                <span class="dollars">${priceDollars}</span>
                ${priceCents !== '00' ? `<span class="cents">${priceCents}</span>` : ''}
              </div>
            </div>
            <div class="col-4 text-center">
              <a class="cta-btn orange outline m-2 ${isProductInCart ? 'view-cart-btn' : ''}" href="${
      cart.cartBaseUrl
    }/cart/?cartId=${localCartId}${testPrice}${couponCode}${groupId}" data-pid="${product.id}" data-cart="add">${
      isProductInCart ? 'View In Cart' : 'Add To Cart'
    }</a>
              <div class="border-top mt-3 pt-3">
                <a class="cta-btn orange outline m-2 ${isProductInCart ? 'view-cart-btn' : ''}" href="${
      cart.cartBaseUrl
    }/cart/?${
      isProductInCart ? `cartId=${localCartId}` : `productId=${product.id}`
    }${testPrice}${couponCode}${groupId}" data-pid="${product.id}">${isProductInCart ? 'View In Cart' : 'Add To Cart'}</a>
                <p class="small mb-0">This button goes directly to the cart page, using <em>"productId"</em> query string</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  };

  let productDisplay = '';
  for (let product of products) {
    productDisplay += displayTemplate(product);
  }

  const productCatalogs = document.querySelectorAll('.display-catalog');
  for (let productCatalog of productCatalogs) {
    productCatalog.innerHTML = productDisplay;
  }
};
